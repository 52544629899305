import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

import VueI18n from 'vue-i18n';
Vue.use(VueI18n);

import updateElementUiTheme from "update-element-ui-theme";

updateElementUiTheme({
  theme: "#C91820",
  // themeName: "--my-theme",
});

const i18n = new VueI18n({
  locale: window.localStorage.getItem('lang') ? window.localStorage.getItem('lang') : 'zh',
  messages: {
    'zh': require('@/assets/lang/zh.js'),
    'en': require('@/assets/lang/en.js')
  }
});

Vue.filter("prefixed",(d)=>{
	const API_HOST = 'https://ydyf.zyyup.com/storage/';
	if(!d || (typeof d !== 'string')) return ''
	return d.startsWith('http')?d:(API_HOST + d)
})

Vue.config.productionTip = false


let $root = new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');

window.$root = $root;

// document.title = '医德医风'
